import { updateCustomAttribute } from '../slices/usersSlice';
import store from '../store';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import { updateUser, updateUserToken } from '../slices/usersSlice';
import axios from 'axios';

store.subscribe(listener);

function select(state) {
  return state.user
}

function listener() {
  return select(store.getState())
}

function  updateUserAttributes(postdata) {
  
  
  let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://yejlguurmpfrrubkigegemfscu0zdnjs.lambda-url.us-west-2.on.aws/',
      headers: { 
      'Content-Type': 'application/json'
      },
      data: postdata
  };
  
  axios.request(config)
  .then((response) => {
    postdata.UserAttributes.forEach(userattribute => {
        store.dispatch(
          updateCustomAttribute( 
            {"attributeName": userattribute["Name"], "attributeValue": userattribute["Value"]}));
    });
  })
  .catch((error) => {
      console.log(error);
  });
}


export async function updateUserAttributesAPI(data) {
  // EX : Data 
  // let data = 
  // [{ "Name": "custom:learning-level", "Value": name }, 
  //   { "Name": "custom:learning-level-value", "Value": value }, 
  // ];

  let user = listener();
  let token = user.userToken;
  let differenceInMinutes = 10

  if (user.userInfo) {
    //check if user token is valid or not 
    let exptime = user.userInfo.signInUserSession.accessToken.payload.exp;

    const rightNow = moment().utc().format("YYYY-MM-DD hh:mm:ss");
    const tokenExp = moment(Number(exptime)*1000).utc().format("YYYY-MM-DD hh:mm:ss");
    differenceInMinutes = moment(tokenExp).diff(rightNow, 'minutes');

  }

  if (differenceInMinutes <= 5) {
    Auth.currentAuthenticatedUser({
      bypassCache: true 
    }).then(user => {
      token = user.signInUserSession.accessToken.jwtToken;

      store.dispatch(updateUser(user));
      store.dispatch(updateUserToken(token));
      
      updateUserAttributes({
        "UserAttributes": data,
        "AccessToken": token
      })


    }).catch(err => console.log(err));
  } else {
    updateUserAttributes({
      "UserAttributes": data,
      "AccessToken": token
    })
  }
  
}
