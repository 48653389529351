import React from 'react';
import { BarsScaleFade, ThreeDotsFade } from "react-svg-spinners";
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useMemo } from 'react';

function Spinner() {
  const { t } = useTranslation();
  const [content, setContent] = useState('Preparing content for you');
  let [indx, setIndx] = useState(0);

  const messages = useMemo(() => {
    return [`Your wait is worth it! We're loading amazing content just for you`, 
    `Thanks for your patience! We're working hard to bring you the best experience`, 
    `We're fetching your content. Hang tight!`, 
    `Preparing content for you`
  ];      
  }, []); 

  useEffect(() => {
    const interval = setInterval(() => {
      setContent(messages[indx]);
      if (indx >= messages.length - 1){
        setIndx(0);
      } else {
        setIndx((indx) => indx + 1)
      }
      
    }, 8000);

    return () => clearInterval(interval);
  }, [indx, messages]);

  return (
    <div className="fixed top-0 left-0 z-50 w-screen h-screen items-center justify-center flex" >
      <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col m-8">
        <BarsScaleFade width="50" height="100" color="rgb(126 34 206)"/>
        <div className="text-gray-500 text-2l font-medium m-8 text-center">
          <span className="inline-flex items-center">
            <span className='mr-2'>{t(content)}</span> 
            <ThreeDotsFade color="rgb(126 34 206)" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
