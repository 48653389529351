import { createSlice } from '@reduxjs/toolkit'


export const lessonsSlice = createSlice({

  name: 'lessonsdata',
  
  initialState: {
    loading: false,
    error: null,
    success: false,
    dailyLessons: []
  },

    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
  reducers: {

    loadDailyLessons: (state, action) => {
      localStorage.setItem("dailyLessons", JSON.stringify(action.payload))
      state.dailyLessons = action.payload
    },

    
  }  

})

export const getUserDailyLessons = (state) => (localStorage.getItem("dailyLessons") && JSON.parse(localStorage.getItem("dailyLessons"))) || []

export const {loadDailyLessons} = lessonsSlice.actions

export default lessonsSlice.reducer
