import store from '../store';
import axios from 'axios'

store.subscribe(listener);

function select(state) {
  return state.user
}

function listener() {
  return select(store.getState())
}

export async function updateTokensAPI(amount){
  console.log(`update tokens : ${amount} ................`);
 
  let user = listener();

  let data = JSON.stringify({
    "userName": user.userInfo.username, 
    "userPoolId": user.userInfo.pool.userPoolId,
    "tokens_to_spend": amount
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://z4qzgaluun6mxdl7ak67eudpia0kvyzp.lambda-url.us-west-2.on.aws/',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  // const axios = require('axios');

  try {
      let response = await axios.request(config);
      return response.data;
  
    } catch (error) {
      console.error(error);
  
    }

}









