import React from 'react';
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import { Header } from './Header';
import { Footer } from './Footer';

const faqs = [
      {
        question: "FAQ.question.1",
        answer:"FAQ.answer.1",
      },  
      {
        question: "FAQ.question.2",
        answer:"FAQ.answer.2",
      },
      {
        question: "FAQ.question.3",
        answer:"FAQ.answer.3",
      },
      {
        question: "FAQ.question.4",
        answer:"FAQ.answer.4",
      },
      {
        question: "FAQ.question.5",
        answer:"FAQ.answer.5",
      },
      {
        question: "FAQ.question.6",
        answer:"FAQ.answer.6",
      },
      {
        question: "FAQ.question.7",
        answer:"FAQ.answer.7",
      },         
]


export function Faq() {

  const { t } = useTranslation();

  return (
    <>
        <Header/>
        <header className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
            <h1 className="text-lg font-semibold leading-6 text-gray-900">{t('Frequently asked questions')}</h1>
          </div>
        </header>
        <main>
            <div className="mx-auto max-w-7xl px-4 py-2 sm:py-4 lg:px-2 lg:py-4">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                    {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                        {({ open }) => (
                        <>
                            <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                <span className="text-base font-semibold leading-7">{t(faq.question)}</span>
                                <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                    <MinusSmallIcon className="h-6 w-6 text-purple-800 font-extrabold" aria-hidden="true" />
                                ) : (
                                    <PlusSmallIcon className="h-6 w-6 text-purple-800 font-extrabold" aria-hidden="true" />
                                )}
                                </span>
                            </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">{t(faq.answer)}</p>
                            </Disclosure.Panel>
                        </>
                        )}
                    </Disclosure>
                    ))}
                </dl>
                </div>
            </div>
        </main>
        <Footer />
    </>
  );
}
