import React from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate} from 'react-router-dom';


function Logout() {



  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      
      await Auth.signOut();
  
      // Optionally clear localStorage manually
      localStorage.clear();

      navigate('/login');
  
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };


  return (
    <center>
    <button type="button" 
      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  
      onClick={handleLogout}>Logout</button>
    </center>

  );

}

export default Logout;








