import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, Navigate } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { LearnSettings } from './LearnSettings';

export function LearningApp() {

    const { route } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    const location = useLocation();

    if (route !== 'authenticated') {
        return <Navigate to="/login" state={{ from: location }} />;
    }    

    return (
        <>
            <div className="min-h-full">
              <Header />
              <main>
              <div className="mx-auto max-w-7xl md:px-8 lg:py-2">
              <LearnSettings />
              <Outlet />
              </div>
              </main>
              <Footer />
            </div>
        </>

    );
}


