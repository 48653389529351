import React from 'react';
import { Fragment, useState} from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Header } from './Header';
import { Footer } from './Footer';
import { useNavigate } from 'react-router-dom';


export function DeleteProfile() {

    const [deleteProfile, setDeleteProfile] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const deleteUserProfile = (deleteuser) => {
        setDeleteProfile(deleteuser)
        navigate('/home');
    }

    return (
        <>
        <Header />
        <main className="grid min-h-full place-items-center bg-white px-96 py-64">
            <div className="text-center">
            </div>
        </main>  

        <Dialog open={deleteProfile} onClose={setDeleteProfile} className="relative z-10">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
                <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <XCircleIcon aria-hidden="true" className="h-12 w-12 text-red-600" />
                    
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Delete Profile
                    </DialogTitle>
                    <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        Are you sure want to delete profile permanently? <br/>
                        Doing so you will not have access to byte learning anymore.
                    </p>
                    </div>
                </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                    type="button"
                    onClick={() => deleteUserProfile(false)}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                    Delete
                </button>
                <button
                    type="button"
                    data-autofocus
                    onClick={() => deleteUserProfile(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                >
                    Cancel
                </button>
                </div>
            </DialogPanel>
            </div>
        </div>
        </Dialog>
      

        <Footer />
        </>
    );
}
