import './App.css';
import '../node_modules/flag-icons/css/flag-icons.min.css'
import { Home } from './components/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { RequireAuth } from './RequireAuth';
import { Profile } from './components/Profile';
import { Login } from './components/Login';
import { LearningApp } from './components/LearningApp';
import { Faq } from './components/Faq';
import { PageNotFound } from './components/PageNotFound';
import { DeleteProfile } from './components/DeleteProfile';
import { Privacy } from './components/Privacy';
import { TermsAndConditions } from './components/TermsAndConditions';
import React from 'react';
import Logout  from './components/Logout';

function MyRoutes() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<LearningApp />} >
            <Route index element={<Home />} /> 
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/home"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
          </Route>
          <Route exact path="/privacy" element={<Privacy />}/>
          <Route exact path="/terms" element={<TermsAndConditions />}/>
          <Route exact path="/faq" element={<Faq />}/>
          <Route exact path="/deleteme" element={<DeleteProfile />}/>
          <Route exact path="/logout" element={<Logout />}/>      
          <Route path='*' element={<PageNotFound />}/>
        </Routes>
      </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}


export default App;
