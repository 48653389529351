import { DailyPhrase } from './DailyPhrase';
import { Link } from 'react-router-dom';
import { LearnModules } from './LearnModules';
import { Profile } from './Profile';


export function Home() {
  return (
    <>
            {/* Profile */}   
            <Profile />
            {/* Daily */}   
            <DailyPhrase />    
             {/* Modules Section Listnening / Speaking / Reading / Writing */}
             <LearnModules />
    </>
  )
}
