import { createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify';

export let usersSlice = createSlice({

  name: 'userdata',
  
  initialState: {
    loading: false,
    userInfo: null,
    userToken: null,
    error: null,
    success: false,
 },

    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
  reducers: {

    loadUser: (state, action) => {
      state.userInfo = action.payload
      state.userToken = action.payload.signInUserSession.accessToken.jwtToken
    },

    updateUser: (state, action) => ({
      ...state, userInfo: action.payload
    }),

    updateUserToken: (state, action) => ({
      ...state, userToken:action.payload
    }),


    updateCredits: (state, action) => ({
      ...state, 
       userInfo: {
          ...state.userInfo,
          attributes: {
              ...state.userInfo.attributes, 
              'custom:token-balance': action.payload
          }
       }
    }),

    updateCustomAttribute: (state, action) => (
      {
      ...state, 
       userInfo: {
          ...state.userInfo,
          attributes: {
              ...state.userInfo.attributes, 
                [action.payload.attributeName]: action.payload.attributeValue
          }
       }
    }),

    updateUserLanguageLevel: (state, action) => ({
      ...state, 
       userInfo: {
          ...state.userInfo,
          attributes: {
              ...state.userInfo.attributes, 
              'custom:learning-language': action.payload
          }
       }
    }),

    logoutUser: (state, action) => ({
     ...state, userInfo:null
    }),

    getAuthUser: () => {
        Auth.currentAuthenticatedUser({
            bypassCache: true 
        }).then(user => {
            loadUser(user)
        }).catch(err => console.log(err));

    }
    
  }  

})

export let getUser = (state) => state.userdata.userInfo

export const loadDBUser = () => {
  Auth.currentAuthenticatedUser({
      bypassCache: true 
  }).then(user => {
      loadUser(user)
  }).catch(err => console.log(err));
}

export let {loadUser, updateUser, updateCredits, logoutUser, updateUserAttributes,
  updateUserLanguageLevel, updateCustomAttribute, updateUserToken } = usersSlice.actions

export default usersSlice.reducer
