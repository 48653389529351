import React from 'react';
import {buyListeningExercise, ListeningExercise } from './ListeningExercise';
import { SpeakingExercise } from './SpeakingExercise';
import { ReadingExercise } from './ReadingExercise';
import { WritingExercise } from './WritingExercise';


export function LearnModules() {

  return (
    <>
      <div className="bg-white py-8 mt-4">
        <div className="mx-auto max-w-7xl px-6 text-center lg:px-6">
            <ul
              className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <ListeningExercise />
                <SpeakingExercise />
                <ReadingExercise />
                <WritingExercise />
            </ul>
        </div>
      </div>
    </>
  );
}
