import React from 'react';
import { Link } from 'react-router-dom';
import listeningimg from '../img/listening.jpg';
import { Fragment, useState, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import tokenImgUrl from '../img/token.png';
import {getExercise, getListeningExercise, retrieveS3DataAPI} from './CohortAPI';
import { useSelector } from 'react-redux';
import Spinner from './Spinner';
import { speechAPI } from './SpeechAPI';
import { useRef } from 'react';
import { updateUserAttributesAPI } from './UpdateUserAttributesAPI';
import { updateTokensAPI } from './TokenAPI';
import hash from 'object-hash';

// test to manually build pipeline
let phrase = {};
if ((localStorage.getItem("dailyLessonsFromAPI"))){
  phrase=JSON.parse(localStorage.getItem("currentPhrase"));
}else{
  phrase = {"phraseOfTheDay":{
    "text":"defaultPhraseText",
    "translation":"translation"
  }}
}
console.log("phrases");
console.log(phrase);
let userName = localStorage.getItem("firstName");
export const listeningPractice = {
  original_phrase:"default",
  state:"default",
  text1: `${localStorage.getItem("firstName")}, I'm going to help you practice your listening skills with a brief exercise. It's based on a very useful phrase that I believe will come in handy during your travels. The phrase is "${phrase && phrase.phraseOfTheDay.text}", which means "${phrase && phrase.phraseOfTheDay.translation}". I'll give you an audio response with a translation to this below so that you can to listen to it.`,
  text2: `Imagine you're walking through the streets of a town. You've had a couple of espressos, and now you need to find a bathroom. You're going to ask a local for directions, and they'll give you some instructions. Listen carefully to what they say, and afterwards, I'll ask you a few questions to see how much you understood.`,
  audioResponse:``,
  audioResponseTranslation:``,
  text3: "Here we go. I'll speak as if I'm the local person you've asked for help.",
  text4: `Okay, ${localStorage.getItem("firstName")}, let's see how you did. Here are your questions`,
  questions: [
              {id: "1", ques: "What landmarks will you pass on the way to the bathroom?" } , 
              {id: "2", ques: "What should you do when you reach the fountain?" },
              {id: "3", ques: "Where is the bathroom located in relation to the bookstore?"}
            ],
  text5: "Take your time to think about your answers, and remember, this is all about practice. If you need me to repeat anything, just let me know!",
}

const ListItemNumber = ({ count, text }) => {
  return (
    <li key={count} className="text-body-color dark:text-dark-6 flex text-base mb-2">
      <span className="bg-violet-500 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full text-base text-white">
        {count}
      </span>
      {text}
    </li>
  );
};

export function ListeningExercise() {
  // audio code
  const [audioSpeechUrl, setAudioSpeechUrl] = useState('');
  const audioRef= useRef();
  const setAudioPlayBack = () => {
    audioRef.current.playbackRate = 0.85;
  };
  let returnSpeech = useCallback((text)  => {
    speechAPI(text).then((response) => {
      let uInt8Array = new Uint8Array(response.AudioStream.data);
      let arrayBuffer = uInt8Array.buffer;
      var blob = new Blob([arrayBuffer], {type: 'audio/mpeg'});
      const url = URL.createObjectURL(blob);
      setAudioSpeechUrl(url);
    })
  },[]);
  // fetching code
  const [isFetching, setIsFetching] = useState(false);
  console.log("Opening Listening Module");
  const user = useSelector(state => state.user.userInfo);
  
  let lang;
  let native;

  console.log(user);
  if(user != null){
    lang = user.attributes['custom:learning-language'];
    native = user.attributes['custom:native-language'];
  }else{
    lang = localStorage.getItem("learningLanguage");
    native = localStorage.getItem("currentLanguage");
  }
  const [openListening, setOpenListening] = useState(false);
  const { t } = useTranslation();
  
  let learnExercise =  useCallback(() => {
    let listeningPracticeStored = JSON.parse(localStorage.getItem("listeningPracticeStored"));
    if(user != null){
      lang = user.attributes['custom:learning-language'];
      native = user.attributes['custom:native-language'];
    }else{
      lang = localStorage.getItem("learningLanguage");
      native = localStorage.getItem("currentLanguage");
    }
    setIsFetching(true);
    //if the listening lesson default phrase doesn't match the current phrase we run the timeout and reload the function until this condition is met
    phrase=JSON.parse(localStorage.getItem("currentPhrase"));
    if(!listeningPracticeStored || listeningPracticeStored.original_phrase !== phrase.phraseOfTheDay.text ){
              const timer = setTimeout(() => {
                learnExercise();
                console.log("P8: no Listening lesson yet, running loop again");
                console.log(listeningPracticeStored.original_phrase);
                console.log( phrase.phraseOfTheDay.text);
              }, 1000);
              return () => clearTimeout(timer);
    }
    if (phrase){
      phrase = JSON.parse(localStorage.getItem("currentPhrase"));
      if (!listeningPracticeStored || listeningPracticeStored.original_phrase !== phrase.phraseOfTheDay.text ){// if lessons don't match, retrieve new one and spend token
        updateTokensAPI(2).then(function(response){
          if (!response){
            alert('update tokens failed');
            setIsFetching(false);
            return false;
          }else if (response.body['message'] == 'Insufficient Tokens.'){
              setIsFetching(false);
              console.log('Insufficient funds, please reload tokens..........');
              alert('Insufficient Funds ..............');
              return false;
          }else{
              let data = [{ "Name": "custom:token-balance", "Value": response.body["tokens left"].toString()}];
              updateUserAttributesAPI(data);
            }
      })
      } else {// if lesson is already loaded no tokens will be spent
        if(listeningPractice.state=="default" || listeningPractice.state=="cached" ){
          listeningPractice.text1=listeningPracticeStored.text1;
          listeningPractice.text2=listeningPracticeStored.text2;
          listeningPractice.text3=listeningPracticeStored.text3;
          listeningPractice.text4=listeningPracticeStored.text4;
          listeningPractice.text5=listeningPracticeStored.text5;
          listeningPractice.questions=listeningPracticeStored.questions;
          listeningPractice.original_phrase=phrase.phraseOfTheDay.text;
          listeningPractice.audioResponse = listeningPracticeStored.audioResponse;
          returnSpeech(listeningPractice.audioResponse);
          listeningPractice.audioResponseTranslation = listeningPracticeStored.audioResponseTranslation;
          listeningPractice.state="cached";       
          setIsFetching(false);
          setOpenListening(true);
        }else if(listeningPractice.state=="floating"){
          updateTokensAPI(2).then(function(response){
            listeningPractice.text1=listeningPracticeStored.text1;
            listeningPractice.text2=listeningPracticeStored.text2;
            listeningPractice.text3=listeningPracticeStored.text3;
            listeningPractice.text4=listeningPracticeStored.text4;
            listeningPractice.text5=listeningPracticeStored.text5;
            listeningPractice.questions=listeningPracticeStored.questions;
            listeningPractice.original_phrase=phrase.phraseOfTheDay.text;
            listeningPractice.audioResponse = listeningPracticeStored.audioResponse;
            returnSpeech(listeningPractice.audioResponse);
            listeningPractice.audioResponseTranslation = listeningPracticeStored.audioResponseTranslation
            listeningPractice.state="cached";              
            setIsFetching(false);
            setOpenListening(true);
          })
        }
      }
    }
  }, [])

  return (
    <>
      <li key="listeningid">
        <img className="mx-auto h-72 w-72 rounded-full" src={listeningimg} alt="" />
        <Link className="relative px-7 py-2 font-medium text-white group mt-4" onClick={() => learnExercise(user)}>
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-purple-500 group-hover:bg-purple-700 group-hover:skew-x-12"></span>
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-purple-700 group-hover:bg-purple-500 group-hover:-skew-x-12"></span>
          <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-purple-600 -rotate-12"></span>
          <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-purple-400 -rotate-12"></span>
          <span className="relative">{t('Listening')}</span>
          <div className="absolute -top-0 -right-0 -mt-3 -mr-4 px-3 py-1 h-9 w-9 rounded-full
                  bg-cover bg-center" style={{backgroundImage: `url(${tokenImgUrl})`}}>
            <div className="font-extrabold text-purple-500 text-lg pr-1 pt-0.5">2</div>
          </div>
        </Link>
      </li>
{/*       <div className="pb-5 bg-purple-200 p-4 shadow-lg rounded-lg m-2">
 */}
      {isFetching &&
          <div id="spinner" className="w-full">
            <Spinner/>
          </div> 
        }

    {/* Learn Daily Phrase Content */}

    <Transition.Root show={openListening} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenListening}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpenListening(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:items-center lg:gap-x-8">


                    {/* Listening Exercise */}
                    <div className="bg-white shadow sm:rounded-lg col-span-12 p-2"> 
                    
                    <div className='pb-5'></div>

                      <div className=" bg-sky-600">
                        <div className="px-4 py-5 sm:p-6">
                          <h2 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-4xl">{t('Listening Lesson')}</h2>
                        </div>
                      </div>

                      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-yellow-700">
                              {listeningPractice.text1}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="border-l-4 border-violet-400 bg-violet-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-violet-800">
                              {listeningPractice.text2}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="border-l-4 border-blue-400 bg-blue-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-blue-800">
                              {listeningPractice.text3}
                            </p>
                            <div className="mt-5">
                                <button
                                // onClick={() => returnSpeech(phrases[0].explanation, phrases[0].language)}
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                  <figure className='inline-flex items-center w-72 lg:w-fit'>
                                    <figcaption>{t('Play Phrase Audio')}:</figcaption>
                                    <pre>  </pre>
                                    <audio id="audioControl" controls src={audioSpeechUrl} ref={audioRef} onCanPlay={() => setAudioPlayBack()}></audio> 
                                  </figure>
                                  
                                </button>
                            </div>
                            <div className="border-l-4 border-slate-400 bg-slate-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-slate-800">
                              {listeningPractice.audioResponseTranslation}
                            </p>
                          </div>
                        </div>
                      </div>                           
                          </div>
                        </div>
                      </div>                                  
                      
                      <div className="border-t-4 border-b-4 border-violet-400 bg-violet-50 shadow-md m-4">
                        <div>
                          <div className="ml-3 text-violet-800">
                             {listeningPractice.text4}:
                              <ol className='p-4'>
                              {listeningPractice.questions.map((question) => (
                                <ListItemNumber key={question.id} count={question.id} text={question.ques} />
                              ))}
                              </ol>
                          </div>
                        </div>
                      </div>

                      <div className="border-l-4 border-slate-400 bg-slate-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-slate-800">
                              {listeningPractice.text5}
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  );
}
