import React from 'react';

import { Fragment, useState, useEffect} from 'react'
import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react'; 
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAttributesAPI } from './UpdateUserAttributesAPI'
import { Auth } from 'aws-amplify';
import { loadUser } from '../slices/usersSlice';


const languages = [
  { id: 1, name: 'Dutch' },
  { id: 2, name: 'English' },
  { id: 3, name: 'French' },
  { id: 4, name: 'German' },
  { id: 5, name: 'Italian' },
  { id: 6, name: 'Swedish' },
  { id: 7, name: 'Spanish' },
]

const nativeLanguages = [
  { id: "nl", name: 'Dutch' },
  { id: "de", name: 'Deutsch' },
  { id: "en", name: 'English' },
  { id: "es", name: 'Español' },
  { id: "fr", name: 'French' },
  { id: "it", name: 'Italian' },
  { id: "sv", name: 'Swedish' },
 ]

 const contexts = [
  {name:"Visiting the Doctor’s office"},
  {name:"Getting around the city"},
  {name:"Around the dinner table"},
  {name:"Small talk for singles"},
  {name:"Talking to employees"},
  {name:"Fundamental business conversation"}
]


const levels = [
  {name: 'Easy',   value: 3},
  {name: 'Medium', value: 7},
  {name: 'Fluent', value: 10},
]

const voices = [
  {name: 'Male'},
  {name: 'Female'},
]

export function Profile() {

  const [openProfile, setOpenProfile] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const user =  useSelector(state => state.user.userInfo);

  let [selectedProfileLearingLanguage, setProfileLearingLanguage] = useState('')
  let [selectedProfileNativeLanguage, setProfileNativeLanguageSelected] = useState('')
  let [selectedProfileContext, setProfileSelectedContext] = useState('')
  let [selectedProfileVoice, setProfileSelectedVoice] = useState('')
  let [selectedProfileLevel, setProfileSelectedLevel] = useState('')
  let [firstname, setFirstname] = useState('')
  let [email, setEmail] = useState('')
  

  useEffect(() => {
    if (user != null) {
        setProfileLearingLanguage(user.attributes['custom:learning-language'] || 'English');
        setProfileNativeLanguageSelected(user.attributes['custom:native-language'] || 'English');
        setProfileSelectedContext(user.attributes['custom:context'] || 'getting around the city' );
        setProfileSelectedVoice(user.attributes['custom:voice'] || 'Male')
        setProfileSelectedLevel(user.attributes['custom:learning-level'] || 'Easy')
        setEmail(user.attributes['email'])
        setFirstname(user.attributes['given_name'])
        if(firstname && email){
          console.log('firstname and email present so closing');
          setOpenProfile(false);
        }else {
          setOpenProfile(true);
        }

    } else {
      Auth.currentAuthenticatedUser({
        bypassCache: true 
      }).then(user => {
        dispatch(loadUser(user))
      }).catch(err => console.log(err));
    }
  }, [user, dispatch]);


  return (
    <>

    <Transition show={openProfile} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpenProfile}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </TransitionChild>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <DialogPanel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-4 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-2">

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-4 sm:grid-cols-12 lg:items-center lg:gap-x-8">

                    {/* Profile*/}
                    <div className="bg-white shadow sm:rounded-lg col-span-12 p-2"> 
                    
                      <div className='pb-2'></div>

                      <div className="bg-sky-600">
                        <div className="px-2 py-2 sm:p-2">
                          <h2 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-4xl">{t('Profile')}</h2>
                        </div>
                      </div>
                      <form className="... group" noValidate>
                        <div className="space-y-12">
                          <div className="border-b border-gray-900/10 pb-2">

                          <div className="mt-2 flex items-center justify-end gap-x-6">
                          <button
                              type="button"
                              className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => setOpenProfile(false)}
                            >
                              {t('Cancel')}
                            </button>
                            <button
                              type="button"
                              className="... group-invalid:pointer-events-none group-invalid:opacity-30 
                                rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick = {() => {
                                  let data = [{ "Name": "given_name", "Value": firstname }, 
                                      { "Name": "email", "Value": email }, ];
                                  updateUserAttributesAPI(data);
                                  setOpenProfile(false)
                                }}
                            >
                              {t('Save')}
                            </button>
                          </div>

                            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
 
                              <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('First name')}
                                </label>
                                <div className="mt-2">
                                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                    <input
                                      id="firstname"
                                      name="firstname"
                                      type="text"
                                      placeholder="First name"
                                      autoComplete="First name"
                                      className="... peer block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ... invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500"
                                      onChange = {(e) => {
                                        setFirstname(e.target.value);
                                      }}
                                      value = {firstname}
                                      required
                                      pattern=".{3,}"

                                    />
                                    <span className="mt-2 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                                      Please enter a valid name min 3 characters
                                    </span>                                    
                                  </div>
                                </div>
                              </div>

                              <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email
                                </label>
                                <div className="mt-2">
                                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                    <input
                                      id="email"
                                      name="email"
                                      type="email"
                                      placeholder="Email"
                                      autoComplete="Email"
                                      className="... peer block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                      onChange = {(e) => {
                                        setEmail(e.target.value);
                                      }}
                                      value = {email}
                                      required
                                    />
                                    <span className="mt-2 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                                      Please enter a valid email address
                                    </span>
                                  </div>
                                </div>
                              </div>


                              <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('Native Language')}
                                </label>
                                <div className="mt-2">
                                  <Listbox id="nativeLanguage" value={selectedProfileNativeLanguage} onChange = {(selectedProfileNativeLanguage) => {
                                        setProfileNativeLanguageSelected(selectedProfileNativeLanguage.name);
                                        let data = [{ "Name": "custom:native-language", "Value": selectedProfileNativeLanguage }];
                                        updateUserAttributesAPI(data);
                                  }}>
                                      <div className="relative mt-2">
                                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                          <span className="block truncate">{selectedProfileNativeLanguage}</span>
                                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                                          </span>
                                        </ListboxButton>

                                        <ListboxOptions
                                          transition
                                          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                        >
                                          {nativeLanguages.map((languagenative) => (
                                            <ListboxOption
                                              key={languagenative.id}
                                              value={languagenative.name}
                                              className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                            >
                                              <span className="block truncate font-normal group-data-[selected]:font-semibold">{languagenative.name}</span>

                                              <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                              </span>
                                            </ListboxOption>
                                          ))}
                                        </ListboxOptions>
                                      </div>
                                    </Listbox>
                                </div>
                              </div>

                              <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('Learning Language')}
                                </label>
                                <div className="mt-2">
                                  <Listbox value={selectedProfileLearingLanguage} onChange = {(selectedProfileLearingLanguage) => {
                                        setProfileLearingLanguage(selectedProfileLearingLanguage.name);
                                        let data = [{ "Name": "custom:learning-language", "Value": selectedProfileLearingLanguage }];
                                        updateUserAttributesAPI(data);
                                    }}>
                                    <div className="relative mt-2">
                                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selectedProfileLearingLanguage}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                                        </span>
                                      </ListboxButton>

                                      <ListboxOptions
                                        transition
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                      >
                                        {languages.map((language) => (
                                          <ListboxOption
                                            key={language.id}
                                            value={language.name}
                                            className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                          >
                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">{language.name}</span>

                                            <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                            </span>
                                          </ListboxOption>
                                        ))}
                                      </ListboxOptions>
                                    </div>
                                  </Listbox>
                                </div>
                              </div>

                              <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('Learning Context')}
                                </label>
                                <div className="mt-2">
                                  <Listbox value={selectedProfileContext} onChange = {(selectedProfileContext) => {
                                        setProfileSelectedContext(selectedProfileContext.name);
                                        let data = [{ "Name": "custom:context", "Value": selectedProfileContext }];
                                        updateUserAttributesAPI(data);
                                    }}>
                                      <div className="relative mt-2">
                                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                          <span className="block truncate">{selectedProfileContext}</span>
                                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                                          </span>
                                        </ListboxButton>

                                        <ListboxOptions
                                          transition
                                          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                        >
                                          {contexts.map((context) => (
                                            <ListboxOption
                                              key={context.name}
                                              value={context.name}
                                              className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                            >
                                              <span className="block truncate font-normal group-data-[selected]:font-semibold">{context.name}</span>

                                              <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                              </span>
                                            </ListboxOption>
                                          ))}
                                        </ListboxOptions>
                                      </div>
                                    </Listbox>
                                </div>
                              </div>   


                              <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('Level')}
                                </label>
                                <div className="mt-2">
                                  <Listbox value={selectedProfileLevel} onChange = {(selectedProfileLevel) => {
                                        setProfileSelectedLevel(selectedProfileLevel.name);
                                        let data = [{ "Name": "custom:learning-level", "Value": selectedProfileLevel }];
                                        updateUserAttributesAPI(data);
                                    }}>
                                    <div className="relative mt-2">
                                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selectedProfileLevel}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                                        </span>
                                      </ListboxButton>

                                      <ListboxOptions
                                        transition
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                      >
                                        {levels.map((level) => (
                                          <ListboxOption
                                            key={level.name}
                                            value={level.name}
                                            className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                          >
                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">{level.name}</span>

                                            <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                            </span>
                                          </ListboxOption>
                                        ))}
                                      </ListboxOptions> 
                                    </div>
                                  </Listbox>
                                </div>
                              </div>

                              <div className="sm:col-span-4">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                {t('Voice')}
                                </label>
                                <div className="mt-2">
                                  <Listbox value={selectedProfileVoice} onChange = {(selectedProfileVoice) => {
                                        setProfileSelectedVoice(selectedProfileVoice.name);
                                        let data = [{ "Name": "custom:voice", "Value": selectedProfileVoice }];
                                        updateUserAttributesAPI(data);
                                    }}>
                                    <div className="relative mt-2">
                                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selectedProfileVoice}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                                        </span>
                                      </ListboxButton>

                                      <ListboxOptions
                                        transition
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                      >
                                        {voices.map((voice) => (
                                          <ListboxOption
                                            key={voice.name}
                                            value={voice.name}
                                            className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                          >
                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">{voice.name}</span>

                                            <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                            </span>
                                          </ListboxOption>
                                        ))}
                                      </ListboxOptions> 
                                    </div>
                                  </Listbox>
                                </div>
                              </div>

    


    
    
    
                                </div>
                          </div>
                        </div>

                        <div className="mt-2 flex items-center justify-end gap-x-6">
                          <button
                              type="button"
                              className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => setOpenProfile(false)}
                            >
                              {t('Cancel')}
                            </button>
                            <button
                              type="button"
                              className="... group-invalid:pointer-events-none group-invalid:opacity-30 
                                rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick = {() => {
                                  let data = [{ "Name": "given_name", "Value": firstname }, 
                                      { "Name": "email", "Value": email }, ];
                                  updateUserAttributesAPI(data);
                                  setOpenProfile(false)
                                }}
                            >
                              {t('Save')}
                            </button>
                        </div>

                      </form>

                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
    </>
  );
}
