import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { BarsArrowDownIcon} from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAttributesAPI } from './UpdateUserAttributesAPI'
import { loadUser } from '../slices/usersSlice';
import { Auth } from 'aws-amplify';
import tokenImgUrl from '../img/token.png';
import axios from 'axios';

const languages = [
  { name: 'Dutch'   },
  { name: 'English' },
  { name: 'French'  },
  { name: 'German'  },
  { name: 'Italian' },
  { name: 'Swedish' },
  { name: 'Spanish' }
]

export const langVoices={
  "it-IT":{
    "Female":"Bianca",
    "Male":"Adriano"
  },
  "en-US":{
    "Female":"Danielle",
    "Male":"Joey"  
  },
  "fr-FR":{
    "Female":"Lea",
    "Male":"Remi"  
  },
  "ja-JP":{
    "Female":"Tomoko",
    "Male":"Takumi"  
  },
  "de-DE":{
    "Female":"Vicki",
    "Male":"Daniel"
  },
  "es-ES":{
    "Female":"Lucia",
    "Male":"Sergio"

  },
  "sv-SE":{
    "Female":"Elin",
    "Male":"Elin"
  },
  "nl-NL":{
    "Female":"Laura",
    "Male":"Laura"
  }
}

export const languageCodeMapper = {
  "Italian":"it-IT",
  "French":"fr-FR",
  "Japanese":"ja-JP",
  "German":"de-DE",
  "Russian":"ru-RU",
  "Spanish":"es-ES",
  "Romanian":"ro-RO",
  "Swedish":"sv-SE",
  "Dutch":"nl-NL",
  "English":"en-US"
}

const contexts = [
  {name:"Visiting the Doctor’s office"},
  {name:"Getting around the city"},
  {name:"Around the dinner table"},
  {name:"Small talk for singles"},
  {name:"Talking to employees"},
  {name:"Fundamental business conversation"}
]

export const levels = [
  {name: 'Easy',   value: 3},
  {name: 'Medium', value: 7},
  {name: 'Fluent', value: 10},
]

const voices = [
  {name: 'Male'},
  {name: 'Female'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// create checkout form with current user's customer ID
async function createCheckout(customer_id, price_id){
  console.log(customer_id);
  const qs = require('qs');
  let data = qs.stringify({
    'success_url': 'https://www.lingua-snacks.com/',
    // 'success_url':'http://localhost:3000',
    'customer': customer_id,
    'line_items[0][adjustable_quantity][enabled]': 'true',
    'line_items[0][price]': price_id,
    'line_items[0][quantity]': '1',
    'mode': 'payment' 
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.stripe.com//v1/checkout/sessions',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': 'Bearer sk_test_51NVF7VFsqHrIFOq42PGe00uQR6DdmmzAzJoxY7vLRQSkIswE0rZuvqbaR7Vjkej9FO03lI0BIHvgbOBuGwqWMVnA00wYvSD9os'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    window.open(response.data.url,"_self");
  })
  .catch((error) => {
    console.log(error);
    return null;
  });
}

export function LearnSettings() {
  const [isFetching, setIsFetching] = useState(false);
  let [lessons, setLessons, setPhrases] = useState([]);
  const { t } = useTranslation();
  let [availableCredits, setAvailableCredits] = useState('');
  let [custId, setCustId] = useState('');
  let [languageLevel, setLanguageLevel] = useState('');
  let [languageLearning, setLanguageLearning] = useState('');
  let [context, setContext] = useState('');
  let [voice, setVoice] = useState('');
  
  const dispatch = useDispatch()
  const user =  useSelector(state => state.user.userInfo);

  function changeLanguageLevel(name) {
     let data = 
        [{ "Name": "custom:learning-level", "Value": name }];
    updateUserAttributesAPI(data);
    // setIsFetching(true);
    // retrievePhrasesAPI(user).then(function(phrases){
    //   console.log("done fetching phrases = "+ JSON.stringify(phrases));
    //   setIsFetching(false);
    //   // getNewLessonsFromPhrasesAPI(user, phrases);
    // });
  }

  function changeLanguageLearning (language) {
    let data = [{ "Name": "custom:learning-language", "Value": language }];
    updateUserAttributesAPI(data);
    // setIsFetching(true);
    // retrievePhrasesAPI(user).then(function(phrases){
    //   console.log("done fetching phrases = "+ JSON.stringify(phrases));
    //   setIsFetching(false);
    //   // getNewLessonsFromPhrasesAPI(user, phrases);
    // });

  }

  function changeContext (context) {
    let data = [{ "Name": "custom:context", "Value": context }];
    updateUserAttributesAPI(data);
    // setIsFetching(true);
    // retrievePhrasesAPI(user).then(function(phrases){
    //   console.log("done fetching phrases = "+ JSON.stringify(phrases));
    //   setIsFetching(false);
    //   // getNewLessonsFromPhrasesAPI(user, phrases);
    // });

    // setLessons(phrases);
    // setDailyPhrase(phrases[0]);
    // dispatch(loadDailyLessons(phrases));
  }

  function changeVoice (voice) {
    let data = [{ "Name": "custom:voice", "Value": voice }];
    updateUserAttributesAPI(data);
  }

  useEffect(() => {
    if (user != null) {
        console.log("USER ATTRS = "+ JSON.stringify(user.attributes));
        setCustId(user.attributes['custom:customer_id']);
        setAvailableCredits(user.attributes['custom:token-balance'] || 0);
        setLanguageLevel(user.attributes['custom:learning-level'] || 'Medium');
        setLanguageLearning(user.attributes['custom:learning-language'] || 'English');
        localStorage.setItem("currentLanguage", user.attributes['custom:native-language']);
        localStorage.setItem("learningLanguage", user.attributes['custom:learning-language']);
        localStorage.setItem("firstName", user.attributes['given_name']);
         // test push to reset white screen     
        setContext(user.attributes['custom:context'] || 'getting around the city' );
        setVoice(user.attributes['custom:voice'] || 'Male')
    } else {
      Auth.currentAuthenticatedUser({
        bypassCache: true 
      }).then(user => {
        dispatch(loadUser(user))
      }).catch(err => console.log(err));
    }
  }, [user, dispatch]);

  return (
    <>
      <nav className="flex border-b border-gray-200 bg-purple-500 shadow-md shadow-purple-500 lg:shadow-white lg:rounded-md" aria-label="learningsettings">
        <ol className="flex w-full max-w-screen-xl ml-2 mr-2 lg:ml-0">

        <li key="language" className="flex">
          <div className="flex items-center">
              
              <Menu as="div" className="relative inline-block text-clip">
                <div>
                  <Menu.Button className="flex items-end rounded-full bg-yellow-500 shadow-lg shadow-purple-900 p-1.5 lg:p-2 lg:ml-4">
                    <span className="sr-only">Open options</span>
                    <BarsArrowDownIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 
                    lg:w-52 w-32 origin-top-right bg-white ring-opacity-5 
                    focus:outline-none ring-1 ring-inset ring-purple-500
                    rounded-sm border-b-4 border-purple-800 shadow-lg shadow-gray-600">
                    {languages.map((language) => (
                        <div key={language.name}>
                        <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="#"
                            className={classNames(
                              active ? 'bg-purple-600 text-white' : 'text-gray-700',
                              'block px-4 py-2 text-sm border-b-2 border-purple-200'
                            )}
                            onClick={() => changeLanguageLearning(language.name)}
                          >
                            {t(language.name)}
                          </Link>
                        )}
                      </Menu.Item>   
                      </div>                   
                    ))}
                   
                  </Menu.Items>
                </Transition>
              </Menu>
              <h2 className="ml-1 lg:ml-1 text-md font-medium text-white pl-1" >{t('Learning')}: {t(languageLearning)}</h2>
              
          </div>
        </li>

        <li key="level" className="flex">
            <div className="flex items-center">
              <svg
                className="h-16 w-12 text-gray-200 hidden sm:block"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>

              <Menu as="div" className="relative inline-block text-clip">
                <div>
                  <Menu.Button className="flex items-end rounded-full bg-yellow-500 shadow-lg shadow-purple-900 p-1.5 lg:p-2 lg:ml-4">
                    <span className="sr-only">Open options</span>
                    <BarsArrowDownIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 lg:w-52 w-32 origin-top-right bg-white 
                    ring-opacity-5 focus:outline-none ring-1 ring-inset ring-purple-500
                    rounded-sm border-b-4 border-purple-800 shadow-lg shadow-gray-600">
                      {levels.map((level) => (
                        <div key={level.name}>
                        <Menu.Item>
                        {({ active }) => (
                          <Link
                             to="#"
                            className={classNames(
                              active ? 'bg-purple-600 text-white' : 'text-gray-700',
                              'block px-4 py-2 text-sm border-b-2 border-purple-200'
                            )}
                            onClick={() => changeLanguageLevel(level.name)}
                          >
                            {t(level.name)}
                          </Link>
                        )}
                      </Menu.Item>
                      </div>                 
                      ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              <h2 className="ml-1 lg:ml-1 text-md font-medium text-white pl-1">
                {t('Level')}: {t(languageLevel)}
              </h2>
            </div>
          </li>

          <li key="voice" className="flex">
            <div className="flex items-center h-16 w-full">
              <svg
                className="h-16 w-12 text-gray-200 hidden sm:block"
                viewBox="0 0 23 42"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>

              <Menu as="div" className="relative inline-block text-clip">
                <div>
                  <Menu.Button className="flex items-end rounded-full bg-yellow-500 shadow-lg shadow-purple-900 p-1.5 lg:p-2 lg:ml-4">
                    <span className="sr-only">Open options</span>
                    <BarsArrowDownIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 lg:left-0 z-10 mt-2 lg:w-52 w-32 origin-top-right bg-white 
                    ring-opacity-5 focus:outline-none ring-1 ring-inset ring-purple-500
                    rounded-sm border-b-4 border-purple-800 shadow-gray-600">
                     {voices.map((voice) => (       
                        <div key={voice.name}>
                        <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="#"
                            className={classNames(
                              active ? 'bg-purple-600 text-white' : 'text-gray-700',
                              'block px-4 py-2 text-sm border-b-2 border-purple-200'
                            )}
                            onClick={() => changeVoice(voice.name)}
                          >
                            {t(voice.name)}
                          </Link>
                        )}
                      </Menu.Item>
                      </div>                     
                     ))}  
                  </Menu.Items>
                </Transition>
              </Menu>
              <h2 className="ml-1 lg:ml-1 text-md font-medium text-white pl-1">
                {t('Voice')}: {t(voice)}
              </h2>        
            </div>
          </li>
      </ol>
    </nav>
    <nav className="flex border-b border-gray-200 bg-blue-500 mt-1 shadow-sm shadow-blue-400 lg:shadow-white lg:rounded-md" aria-label="Breadcrumb">
        <ol className="flex w-full max-w-screen-xl  m-2 lg:m-0">
          <li key="context" className="flex">
            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-clip">
                <div>
                <Menu.Button className="flex items-end rounded-full bg-yellow-500 shadow-lg shadow-purple-900 p-1.5 lg:p-2 lg:ml-4">
                    <span className="sr-only">Open options</span>
                    <BarsArrowDownIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-1 w-80 lg:w-96  origin-top-right 
                   bg-white ring-opacity-5 focus:outline-none  ring-1 ring-inset ring-purple-500
                     rounded-sm border-b-4 border-purple-800 shadow-lg shadow-gray-600">
                     {contexts.map((context) => (       
                        <div key={context.name}>
                        <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="#"
                            className={classNames(
                              active ? 'bg-purple-600 text-white' : 'text-gray-700',
                              'block px-4 py-2 text-sm border-b-2 border-purple-100'
                            )}
                            onClick={() => changeContext(context.name)}
                          >
                            {t(context.name)}
                          </Link>
                        )}
                      </Menu.Item>
                      </div>                     
                     ))}  
                  </Menu.Items>
                </Transition>
              </Menu>
              <h2 className="ml-1 lg:ml-1 text-md font-medium text-white pl-1">
                {t('Learning Context')}: {t(context)}
              </h2>        
              <svg
                className="h-16 w-12 border-lime-950 flex-shrink-0 text-gray-200 hidden sm:block"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>

            </div>
          </li>
      </ol>
    </nav>


    <div className="pt-7 pb-2">
      <div className="flex justify-between mb-1 pr-4 sm:pr-1 pl-2">
        <span className="lg:text-base text-base font-medium">{availableCredits} {t('credits left in wallet')}</span>
        <span className="text-sm font-medium mb-2 justify-end">
 
       <button
          type="button"
          className="relative group rounded-md bg-purple-700 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-400 focus-visible:outline shadow-purple-500"
          onClick={(event)=>createCheckout(custId, "price_1NxEHEFsqHrIFOq4ULAfV5y8")} 
        >
          {t('Load')} 100 {t('credits')}
          <div className="absolute -top-0 -right-0 -mt-8 -mr-2 px-3 py-1 h-10 w-10 rounded-full
                  bg-cover bg-center" style={{backgroundImage: `url(${tokenImgUrl})`}}>
            <div className="font-extrabold text-purple-500 text-md p-2 -ml-3">$10</div>
          </div>
        </button>
      </span>
      </div>
      <div className="bg-red-400 rounded-full h-3.5 dark:bg-red-300 m-2">
        <div className="bg-green-300 h-3.5 rounded-full" style={{width: (availableCredits > 100 ? 95 : availableCredits) + '%'}} ></div>
      </div>
    </div>

    </>
  );
}
