import store from '../store';
import { langVoices } from './LearnSettings';
import { languageCodeMapper } from './LearnSettings';
import axios from 'axios'

store.subscribe(listener);

function select(state) {
  return state.user.userInfo;
}

function listener() {
  return select(store.getState())
}

export async function speechAPI(text){
  console.log(`retrieve speech mp3 file for the text :  ................` + text);
 
  let user = listener();

  let currentLanguage=user.attributes['custom:learning-language'];
  let currentLanguageCode=languageCodeMapper[currentLanguage] || "en-US";
  let genderVoice = user.attributes['custom:voice'];
  let currentVoice = langVoices[currentLanguageCode][genderVoice] || "Joey";

  let data = JSON.stringify({
    "Engine": "neural",
    "LanguageCode": currentLanguageCode,
    "OutputFormat": "mp3",
    "SampleRate": "24000",
    "Text": `<speak>${text}</speak>`,
    "TextType": "ssml",
    "VoiceId": currentVoice
  });


  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://xx6xqozsz5zu7bti5e2myi7esu0pzuqz.lambda-url.us-west-2.on.aws/',
    headers: { 
        'Content-Type': 'application/json'
    },
  
    data : data
  };


  try {
      let response = await axios.request(config);
      return response.data;
  
    } catch (error) {
      console.error(error);
  
    }

}









