import { configureStore } from '@reduxjs/toolkit'
import usersReducer from './slices/usersSlice'
import lessonsReducer from  './slices/lessonsSlice'

const store = configureStore({
  reducer: {
    user: usersReducer,
    lessons: lessonsReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;