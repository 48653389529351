import { Authenticator, useTheme, View, Image, Text, useAuthenticator, Heading,} from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import loginLogo from '../img/header-logo.png';

export function Login() {
  
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';


  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.zero}>
          <Image
            height="30%"
            width="50%"
            objectPosition="50% 50%"
            alt="Welcome to Lingua-Snacks"
            src={loginLogo}
            objectFit="initial"
            opacity="100%"
          />
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={4}
          >
            Sign in to your account
          </Heading>
        );
      },
    },
  
  }

  
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <View className="auth-wrapper">
      <Authenticator components={components} socialProviders={['facebook']} />
    </View>
  );
}
